import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../components/Layout";
import MetaComponents from "../components/seo/MetaComponents";
import Article from "../components/Article";
import ArticleHeader from "../components/ArticleHeader";
import { breakpoints } from "../constants/breakpoints";

const Impressum = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Impressum" />
            <Article>
                <Table>
                    <tbody>
                        <tr>
                            <td>
                                <strong>Firma:</strong>
                            </td>
                            <td>Miracl GmbH</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Geschäftsführer:</strong>
                            </td>
                            <td>David Savasci</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Geschäftsanschrift:</strong>
                            </td>
                            <td>Lindengasse 26/1/5, 1070 Wien</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Unternehmensgegenstand:</strong>
                            </td>
                            <td>
                                Online-Portal zur Erbringung von Unterstützungsleistungen iZm Immobilien
                                Kreditvermittler im Sinne von § 136e Abs 2 GewO als ungebundener Kreditvermittler
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <strong>Kontakt</strong>
                            </td>
                        </tr>
                        <tr className="no-border">
                            <td>
                                <strong>Telefon:</strong>
                            </td>
                            <td>
                                <a href="tel:+436703085171">+43 670 3085171</a>
                            </td>
                        </tr>
                        <tr className="no-border">
                            <td>
                                <strong>E-Mail:</strong>
                            </td>
                            <td>
                                <a href="mailto:kontakt@miracl.at">kontakt@miracl.at</a>
                            </td>
                        </tr>
                        <tr className="no-border">
                            <td>
                                <strong>Web:</strong>
                            </td>
                            <td>
                                <a href="https://miracl.at/" target="_blank" rel="noreferrer noopener">
                                    miracl.at
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Firmenbuchnummer:</strong>
                            </td>
                            <td>FN 561504 t</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Firmenbuchgericht:</strong>
                            </td>
                            <td>Handelsgericht Wien</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Aufsichtsbehörde:</strong>
                            </td>
                            <td>Magistrat der Stadt Wien</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Kammerzugehörigkeit:</strong>
                            </td>
                            <td>Wirtschaftskammer Österreich: Fachgruppe der Finanzdienstleister</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Anwendbare gewerberechtliche Vorschriften:</strong>
                            </td>
                            <td>
                                Gewerbeordnung 1994 (idgF), link:{" "}
                                <a
                                    href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&amp;Gesetzesnummer=10007517"
                                    target="_blank"
                                    rel="noreferrer noopener nofollow"
                                >
                                    hier
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Umsatzsteuer- Identifikationsnummer:</strong>
                            </td>
                            <td>ATU77148604</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>GISA-Zahl:</strong>
                            </td>
                            <td>34094204</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Gewerbeberechtigung:</strong>
                            </td>
                            <td>
                                <p>
                                    Gewerbliche Vermögensberatung ohne Berechtigung zur Vermittlung von Lebens- und
                                    Unfallversicherungen, eingeschränkt auf Vermittlung von hypothekarisch besicherten
                                    Finanzierungen und Privatkrediten. Berechtigung zur Tätigkeit als ungebundene
                                    Kreditvermittlerin
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Angaben zur Online-Streitbelegung:</strong>
                            </td>
                            <td>
                                Verbraucher haben die Möglichkeit, Beschwerden an die Online-Streitbeilegungsplattform
                                der EU zu richten:{" "}
                                <a href="https://ec.europa.eu/odr" target="_blank" rel="noreferrer noopener nofollow">
                                    https://ec.europa.eu/odr
                                </a>
                                . Sie können allfällige Beschwerde auch an die oben angegebene E-Mail-Adresse richten.
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Article>
        </Layout>
    );
};

const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    border-bottom: 1px solid #ededed;
    color: #677294;
    word-break: keep-all;

    td {
        border-top: 1px solid #ededed;
        padding: 10px 5px;
        vertical-align: top;
    }
    .no-border td {
        border-top: none;
    }

    @media (max-width: ${breakpoints.mobileMax}) {
        td {
            display: block;
            padding: 8px 0;
            font-size: 14px;
        }
        .no-border td {
            border-top: none;
            display: inline-block;
            padding-top: 3px;

            :first-child {
                margin-right: 5px;
            }
        }
    }
`;

export default Impressum;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["header", "footer", "page.impressum"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
